import { Routes, Route, Navigate, Link } from 'react-router-dom'
import { useState } from 'react'
import { useLocation } from 'react-router'
import { LocalStorage } from './services/localStorage/LocalStorage.services'

import NavBar from './components/NavBar/NavBar'
import Footer from './components/Footer/Footer'
import Home from './views/Home/Home'
import Companies from './views/Companies/Companies'
import Headquarters from './views/Headquarters/Headquarters'
import Inspections from './views/Inspections/Inspections'
import EditInspection from './views/Inspections/EditInspection'
import InspectionCalendar from './views/Inspections/CalendarInspections'
import Actions from './views/Actions/Actions'
import EditAction from './views/Actions/EditAction'
import Elements from './views/Elements/Elements'
import Upkeep from './views/Upkeep/Upkeep'
import UpkeepTypes from './views/UpkeepTypes/UpkeepTypes'
import Users from './views/Users/Users'
import LoginPage from './views/Login/LoginPage'
import NewElement from './views/Elements/NewElement'
import GraphicsElement from './views/Elements/GraphicsElement'
import NewUser from './views/Users/NewUser'
import NewCompany from './views/Companies/NewCompany'
import NewHeadquarter from './views/Headquarters/NewHeadquarter'
import NewUpkeep from './views/Upkeep/NewUpkeep'
import NewUpkeepType from './views/UpkeepTypes/NewUpkeepType'
import NewInspection from './views/Inspections/NewInspection'
import image from "./assets/img/logo_sant-tomas.png"

import styles from './App.module.css'

import { LanguageContext } from './components/Context/Context.js'

function App() {

  let location = useLocation()
  // Estat de l'idioma: 0 (Català) - 1 (Castellà) - 2 (Inglès)
  const [ language, setLanguage ] = useState(0)

  const handleLogout = () => {
    LocalStorage.clear()
  }

  return (
    <LanguageContext.Provider value={language}>
      <div className={styles.container}>
        {
          location.pathname !== "/" && location.pathname !== "/login" &&
          <header className={styles.headerSi}>
            <a href="https://www.santtomas.cat/" className={styles.logo} target='blank'><img src={image} alt="logo" /></a>
            <NavBar></NavBar>
            <Link className={styles.logout} onClick={handleLogout} to="/"><span>Logout</span><i className="fa-solid fa-right-from-bracket"></i></Link>
          </header>
        }
          <main>
            <Routes>
              <Route path="/" element={<Navigate to='/login'></Navigate>}></Route>
              <Route path="/login" element={<LoginPage></LoginPage>}/>
              <Route path="/home" element={<Home></Home>}/>
              <Route path="/inspeccions/">
                <Route index element={<Inspections></Inspections>}/>
                <Route path="new-inspeccio" element={<NewInspection></NewInspection>}/>
                <Route path=":id" element={<EditInspection></EditInspection>}/>
                <Route path="inspection-calendar" element={<InspectionCalendar></InspectionCalendar>}/>
              </Route>
              <Route path="/accions/">
                <Route index element={<Actions></Actions>}/>
                <Route path=":id" element={<EditAction></EditAction>}/>
              </Route>
              <Route path="/elements/">
                <Route index element={<Elements></Elements>}/>
                <Route path="new-element" element={<NewElement></NewElement>}/>
                <Route path=":id" element={<NewElement></NewElement>}/>
                <Route path="grafics/:id" element={<GraphicsElement></GraphicsElement>}/>
                {/* <Route path="calendar/:id" element={<ElementCalendar></ElementCalendar>}/> */}
              </Route>
              <Route path="/empreses/">
                <Route index element={<Companies></Companies>}/>
                <Route path="new-empresa" element={<NewCompany></NewCompany>}/>
                <Route path=":id" element={<NewCompany></NewCompany>}/>
              </Route>
              <Route path="/centres/">
                <Route index element={<Headquarters></Headquarters>}/>
                <Route path="new-centre" element={<NewHeadquarter></NewHeadquarter>}/>
                <Route path=":id" element={<NewHeadquarter></NewHeadquarter>}/>
              </Route>
              <Route path="/manteniments/">
                <Route index element={<Upkeep></Upkeep>}/>
                <Route path="new-manteniment" element={<NewUpkeep></NewUpkeep>}/>
                <Route path=":id" element={<NewUpkeep></NewUpkeep>}/>
              </Route>
              <Route path="/tipus-manteniment">
              <Route index element={<UpkeepTypes></UpkeepTypes>}/>
                <Route path="new-tipusmanteniment" element={<NewUpkeepType></NewUpkeepType>}/>
                <Route path=":id" element={<NewUpkeepType></NewUpkeepType>}/>
              </Route>
              <Route path="/usuaris/">
                <Route index element={<Users></Users>}/>
                <Route path="new-user" element={<NewUser></NewUser>}/>
                <Route path=":id" element={<NewUser></NewUser>}/>
              </Route>
            </Routes>
          </main>
          <footer>
            <Footer></Footer>
          </footer> 
      </div>
    </LanguageContext.Provider>
  )
}

export default App;
